import {useState, useEffect} from 'react';
import './App.css';
import abi from './abi.json';
import Web3 from "web3/dist/web3.min.js";
import config from './config.json';

function App() {
  const [web3, setWeb3] = useState(null);
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState("");
  const [minted, setMinted] = useState(0);
  const [mintedPerWallet, setMintedPerWallet] = useState(0);
  const [mintPaused, setMintPaused] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  async function connect() {
    if (window.ethereum) {
      await window.ethereum.request({method: 'eth_requestAccounts'});
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: config.chain }],
      });
      window.web3 = new Web3(window.ethereum);
      setWeb3(window.web3);
      setConnected(true);
      setAddress(window.web3.currentProvider.selectedAddress);
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (connected) {
      refresh();
      setInterval(refresh, 10000);
    }
  }, [connected]);

  async function refresh() {
    const contract = new web3.eth.Contract(abi, config.address);

    const _mintPaused = await contract.methods.mintPaused().call();
    const _minted = await contract.methods.totalSupply().call();
    const _mintedPerWallet = await contract.methods.numberMinted(address).call();

    setMintPaused(_mintPaused);
    setMinted(_minted);
    setMintedPerWallet(_mintedPerWallet);
    setIsLoaded(true);
  }

  useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }, [mintedPerWallet]);

  async function buy() {
    const contract = new web3.eth.Contract(abi, config.address);

    try {
      await contract.methods.mint(2).send({ from: address })
        .on('transactionHash', (receipt) => {
          setIsPending(true)
          alert("Transaction processing...")
        })
        .on('receipt', (receipt) => {
          refresh();
          setIsPending(false)
          setMintedPerWallet(2);
          alert("Success!")
        })
        .on('error', () => {
          setIsPending(false)
          refresh();
          alert("Transaction cancelled.")
        });
    } catch (error) {
      setIsPending(false)
      refresh();
      console.log("Your transaction has failed. Please try again.")
    }
  }

  return (
    <div className="App">
      <div id="backgroundColor" />
      <div id="header">
        <a class="headerLogo" href="https://www.kikomints.com/" ></a>
        <div class="headerOptions">
          <a class="textLink" href="https://www.kikomints.com/"><span>HOME</span></a>
          <a class="textLink" href="https://bakery.kikomints.com/"><span>BAKERY</span></a>
          <a class="iconLink discord" href="https://www.discord.gg/kikomints" />
          <a class="iconLink twitter" href="https://www.twitter.com/kikomints" />
        </div>
      </div>
      <div className="backgroundShop"/>
      <div className="content">
        <h1>
          Bear<br/>
          Market<br/>
          Bakery
        </h1>
        <h3>bear markets are for bakers!</h3>
        <p>
          welcome to our pre-launch bakery. what are we baking? nothing. nothing but <em>free</em> fortune crackers.
        </p>

        <div className="mintinfo">
          {connected
            ? isLoaded && <>
                {minted >= 10000
                  ? <div class="message">
                      <h2>We're all out of fortune crackers!</h2>
                      <div class="options">
                        <a class="small-button discord" href="https://www.discord.gg/kikomints">
                          Discord
                        </a>
                        <a class="small-button opensea" href="https://www.opensea.io/">
                          OpenSea
                        </a>
                        <a class="small-button looksrare" href="https://www.looksrare.org">
                          LooksRare
                        </a>
                      </div>
                    </div>
                  : mintedPerWallet > 0
                    ? <div class="message">
                        <h2>you've claimed your fortunes!</h2>
                        <p>be nice and give others a chance to get in on the fun! wanna help me out with a quick tweet, fren?</p>
                        <div class="options">
                          <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-size="large" data-text="Just got 2 free NFT Fortune Crackers from @KikoMints!" data-url="https://bakery.kikomints.com" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                          <a class="small-button discord" href="https://www.discord.gg/kikomints">
                            Discord
                          </a>
                        </div>
                      </div>
                    : mintPaused
                      ? <>
                          <div class="mintButton">
                            <button disabled class="disabled"><span class="text">WEN MINT? <i class="emoji kikoshrug" /></span></button>
                          </div>
                        </>
                      : <>
                          <div class="mintButton">
                            <button onClick={buy} disabled={isPending}><span class="text">{isPending ? "Minting..." : "MINT 2 NOW!"}</span></button>
                            <div class="stock">{10000-minted} fortunes left</div>
                          </div>
                        </>
                }
              </>
            : <div><button onClick={connect}><span class="text">CONNECT</span></button></div>
          }
        </div>
        <p>
          announced utility? nope <i class="emoji kikoshrug" />
        </p>
        <p>
          chill vibes and random fortunes? tons.
        </p>
        <p>
          seriously. zero expectations. let's just have fun!
        </p>
      </div>
    </div>
  );
}

export default App;
